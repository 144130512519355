










































































































































import { Component, Vue } from "vue-property-decorator";
import uploadNew from "@/components/uploadNew.vue";
import api from "@/api";
import {imageUrl} from '@/utils/common'

@Component({
  components: {
    uploadNew: uploadNew
  }
})
export default class AUser extends Vue {
  baseUrl = imageUrl
  // 表格数据
  tableData = [
    {index: 1}
  ];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  // 搜索条件
  search: any = {
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  config = {
    status: {
      1: '已上架',
      2: '已下架'
    },
    influenceRange: {
      zhike: '掌纷享-直客',
      broker: '掌纷享-经纪人'
    }
  }

  model: any = {
    OSSAccessKeyId: "",
    signature: "",
    policy: "",
    key: "",
    callback: "",
    file: "",
    success_action_status: ''
  };

  actions() {
    return "J27136";
  }

  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
        .post("J27130", this.search)
        .then((data) => {
          this.total = data.total;
          this.tableData = data.list;
        })
        .finally(() => {
          this.loading = false;
        });
  }

  // 新增商品
  addDialog = false
  shopInfo: any = {}
  qudao = [{name: '掌纷享-直客', val: 'zhike'}, {name: '掌纷享-经纪人', val: 'broker'}]
  checked: any =[]
  add() {
    this.checked = []
    this.fileList = {
      image_name: '', image_url: ''
    }
    this.fileList2 = {
      image_name: '', image_url: ''
    }
    this.fileList3 = {
      image_name: '', image_url: ''
    }
    this.shopInfo = {}
    this.addDialog = true
  }

  fileList: any = {
    image_url: '',
    image_name: ''
  }
  fileList2: any = {
    image_url: '',
    image_name: ''
  }
  fileList3: any = {
    image_url: '',
    image_name: ''
  }

  getData1(item: any) {
    this.shopInfo.indexImg = item
  }
  getData2(item: any) {
    this.shopInfo.bannerImg = item
  }
  getData3(item: any) {
    this.shopInfo.detailsImg = item
  }
  sureAdd() {
    if(!this.shopInfo.merchandiseName) {
      this.$message.warning('商品名称不能为空');
      return
    }
    if(!this.shopInfo.fractional) {
      this.$message.warning('商品单价不能为空');
      return
    }
    if(!this.shopInfo.weight && this.shopInfo.weight != 0) {
      this.$message.warning('商品排序不能为空');
      return
    }
    if(!this.checked.length) {
      this.$message.warning('上架渠道不能为空');
      return
    }
    this.shopInfo.influenceRange = this.checked.toString()
    api
      .post(this.shopInfo.merchandiseCode? 'J27132':'J27131', this.shopInfo)
      .then(res => {
        this.$message.success('操作成功')
        this.addDialog = false
        this.searchTable()
      })
  }

  handleCancle() {
    this.shopInfo = {}
    this.addDialog = false
  }

  handleCancle2() {
    this.shopInfo = {}
    this.detailDialog = false
  }

  // 编辑
  async edit(row:any) {
    this.checked = row.influenceRange.split(',')
    this.shopInfo = {...row}
    this.addDialog = true
    this.fileList = {
      image_name: row.indexImg,
      image_url: row.indexImg
    }
    this.fileList2 = {
      image_name: row.bannerImg,
      image_url: row.bannerImg
    }
    this.fileList3 = {
      image_name: row.detailsImg,
      image_url: row.detailsImg
    }
  }

  // 详情
  detailDialog = false
  toDetail(row: any) {
    api
      .post('J27144', {merchandiseCode: row.merchandiseCode})
      .then(async res => {
        this.shopInfo = res
        this.detailDialog = true

      })
  }

  // 操作
  setStatus(row: any, val: any){
    // 1 上架  2 下架 3 删除
    let tips: any = val == 1 ? "确定上架该商品?" : (val == 2 ? '确定下架该商品?' : ( val == 3 ? '确定删除该商品?' : null ) )
    let apiVal: any = val == 1 ? "J27134" : (val == 2 ? 'J27135' : ( val == 3 ? 'J27133' : null ) )
    this.$confirm( tips , "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      api
          .post(apiVal, {merchandiseCode: row.merchandiseCode})
          .then((res: any) => {
            this.$message.success("操作成功");
            this.searchTable()
          })

    });
  }

  activated() {
    this.searchTable();
  }
}
